import {
  WebinarInteraction,
  WebinarInteractionTypes,
} from "@combined-curiosity/collector-js/";
import { useRef, useState } from "react";
import Requires from "../../../internal_components/Requires";
import {
  MessageWithReactionsAndWebinarRegistrations,
  WithReplies,
} from "@/types/index";
import { clientSideSupabase } from "@/utils/xmsSupabase";
import { useTracking } from "../../trackEvent";
import { MessageReactions } from "./MessageReactions";
import { definitions } from "@/types/index";
import DetectClickOutside from "@/utils/detectClickOutside";

type ChatMessageProps = {
  message: WithReplies<MessageWithReactionsAndWebinarRegistrations>;
  align?: "left" | "right";
  leadId?: string;
  toggleReaction?: (
    message: MessageWithReactionsAndWebinarRegistrations,
    reaction: string,
    currentLeadId: string,
    viewType: WebinarInteraction["viewType"]
  ) => void;
  allowReaction?: boolean;
  viewType: WebinarInteraction["viewType"];
  isMod?: boolean;
  webinarInstanceId?: string;
  webinarId?: string;
};

const ChatMessage = ({
  message,
  leadId,
  webinarId,
  toggleReaction,
  align = "left",
  allowReaction = true,
  viewType,
  isMod = false,
  webinarInstanceId,
}: ChatMessageProps) => {
  const [showReactions, setShowReactions] = useState<boolean>(false);
  const { trackWebinarEvent } = useTracking();
  const reactionRef = useRef(null);
  DetectClickOutside(reactionRef, () => setShowReactions(false));

  let isViewer: Boolean = false;
  leadId &&
    message.webinar_registrations?.lead_id === leadId &&
    (isViewer = true);

  const reactToMessage = async (reactionText: string) => {
    // don't add reaction if user has used this reaction on this message already
    const reactionExists = message.message_reactions.find(
      (r) => r.reaction === reactionText && r.lead_id === leadId
    );
    if (reactionExists) {
      return;
    }

    const reaction = clientSideSupabase.ensureOne<
      definitions["message_reactions"]
    >(
      await clientSideSupabase.supabase.from("message_reactions").insert({
        message_id: message.id,
        reaction: reactionText,
        lead_id: leadId,
      })
    );

    if (webinarInstanceId && webinarId) {
      trackWebinarEvent(
        {
          interaction: WebinarInteractionTypes.reacted_to_message.name,
          message: message.message,
          viewType,
          webinarInstanceId,
          reaction: reactionText,
          webinarId,
          anonymousWebinarViewer: false,
        },
        reaction.id
      );
    }
  };

  const formattedName = (
    message: MessageWithReactionsAndWebinarRegistrations
  ) => {
    if (isViewer) {
      return "You";
    }

    return message.agent_name || message.webinar_registrations?.public_name;
  };

  const renderMessage = () => {
    if (align === "left") {
      return (
        <div
          className={`rounded px-4 py-2 break-words max-w-[300px] sm:max-w-[600px] rounded-tl-none ${
            isMod ? "bg-white-300 border" : "bg-slate-100"
          }`}
        >
          {message.message}
        </div>
      );
    }

    // @TODO: make this the viewer's message and float to the right (think iMessage)
    return (
      <div
        className={`rounded px-4 py-2 rounded-tr-none text-right bg-purple-300`}
      >
        {message.message}
      </div>
    );
  };

  const SmallReaction = ({ reaction }: { reaction: string }) => {
    return (
      <div
        className={`${
          allowReaction ? "hover:cursor-pointer hover:bg-slate-200" : ""
        } px-3 rounded`}
        onClick={() => allowReaction && reactToMessage(reaction)}
      >
        {reaction}
      </div>
    );
  };

  const moderatorLabel = () => {
    return (
      <>
        <span className="text-xs font-semibold inline-block py-1 px-2 rounded text-gray-500 border border-gray-300 uppercase ml-2">
          Moderator
        </span>
      </>
    );
  };

  return (
    <div
      data-component="ChatMessage"
      data-message-id={message.id}
      data-offset-in-seconds={message.video_offset_seconds}
      className="pl-2 mb-4"
    >
      <div className="text-xs mb-2 flex justify-between">
        <div className="font-bold">
          {formattedName(message)} {isMod ? moderatorLabel() : null}
        </div>
      </div>
      <div
        className="relative"
        ref={reactionRef}
        onClick={() => allowReaction && setShowReactions((s) => !s)}
      >
        {renderMessage()}

        <Requires value={showReactions}>
          <div className="flex bg-slate-50 text-lg rounded w-fit border shadow-lg absolute -bottom-4 right-0">
            <SmallReaction reaction="👍" />
            <SmallReaction reaction="❤️" />
            <SmallReaction reaction="🙌" />
            <SmallReaction reaction="🙏" />
            <SmallReaction reaction="😂" />
          </div>
        </Requires>
      </div>

      <div className="h-4 mt-2">
        <Requires value={!showReactions && toggleReaction}>
          {toggleReaction && (
            <MessageReactions
              onAdd={() => setShowReactions(true)}
              viewType={viewType}
              allowReaction={allowReaction}
              message={message}
              currentLeadId={leadId}
              toggleReaction={toggleReaction}
            />
          )}
        </Requires>
      </div>

      <div className="flex pl-4 flex-col mt-2 w-[90%]">
        {message.replies?.map((reply) => (
          <ChatMessage
            message={reply}
            key={reply.id}
            webinarInstanceId={webinarInstanceId}
            webinarId={webinarId}
            isMod={true}
            align="left"
            toggleReaction={toggleReaction}
            viewType={viewType}
          />
        ))}
      </div>
      <div className="clear-both" />
    </div>
  );
};

export default ChatMessage;
