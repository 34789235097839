import { WebinarInteraction } from "@combined-curiosity/collector-js";
import CMSButton from "components/SubComponents/CMSButton";
import { useEffect, useState } from "react";
import { generateClientSideUUID } from "../../utils/uuid/uuid";
import { BuyButtonInteractivePointOfInterest } from "../cms/types/cmsTypes";
import { TextRender } from "@/utils/cms/renderableResources";
import CountdownTimer from "components/SubComponents/CountdownTimer";
import ensureTrailingSlash from "@/utils/ensureTrailingSlash";

type videoBuyButtonProps = {
  currentTime: number;
  trackWebinarEvent: (
    meta: WebinarInteraction,
    uniqueId: string,
    transport?: "beacon" | "xhr" | undefined
  ) => void;
  config: BuyButtonInteractivePointOfInterest;
  webinarInstanceId: string;
  webinarId: string;
  viewType: WebinarInteraction["viewType"];
  title: string;
  startsAt?: Date;
  duration: string;
  brandAbbreviation: string;
  isVisible: boolean;
};

const VideoBuyButton = ({
  currentTime,
  trackWebinarEvent,
  viewType,
  webinarInstanceId,
  config,
  webinarId,
  title,
  startsAt,
  duration,
  brandAbbreviation,
  isVisible,
}: videoBuyButtonProps) => {
  const [originalStartsAt] = useState(startsAt);
  const [trackedBuyButtonSeen, setTrackedBuyButtonSeen] = useState(false);
  const [isContentVisible, setContentVisible] = useState(true);
  const [end, setEnd] = useState<Date | undefined>();

  useEffect(() => {
    if (currentTime > config?.starts_at && isVisible) {
      if (!trackedBuyButtonSeen) {
        const uniqueId = generateClientSideUUID();
        trackWebinarEvent(
          {
            interaction: "content_seen",
            breakpointSeen: "buy_button",
            viewType,
            webinarInstanceId,
            webinarId,
            anonymousWebinarViewer: false,
          },
          uniqueId
        );
        setTrackedBuyButtonSeen(true);
      }
    }
  }, [
    currentTime,
    config.starts_at,
    trackWebinarEvent,
    trackedBuyButtonSeen,
    viewType,
    webinarId,
    webinarInstanceId,
    isVisible,
  ]);

  const handleClick = () => {
    const uniqueId = generateClientSideUUID();
    trackWebinarEvent(
      {
        interaction: "clicked_webinar_offer",
        currentVideoOffset: currentTime,
        viewType,
        webinarInstanceId,
        webinarId,
        anonymousWebinarViewer: false,
      },
      uniqueId
    );
    const url = config.metadata.url;
    const utm_medium = "webinar-buy-button";
    const utm_source = "cc-webinar";
    const utm_content = webinarInstanceId;
    const utm_campaign = webinarId;
    const params = new URLSearchParams({
      utm_medium,
      utm_source,
      utm_content,
      utm_campaign,
    });
    window.open(`${ensureTrailingSlash(url)}?${params}`, "_blank");
  };

  useEffect(() => {
    if (!originalStartsAt) {
      return;
    }
    const start = new Date(originalStartsAt);
    const end = new Date(start.getTime() + parseInt(duration) * 1000);

    setEnd(end);
  }, [originalStartsAt, duration]);

  useEffect(() => {
    const popUpBoxElement = document.getElementById("popUpBox");
    if (popUpBoxElement) {
      popUpBoxElement.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  if (!end) {
    return null;
  }

  if (currentTime > config?.starts_at) {
    // TODO - Make this Dynamic based on Brand
    const selectUrlByBrand = (brandAbbreviation: string) => {
      return brandAbbreviation === "nma"
        ? "https://www.hobbyresources.com/nma/resources/fa035aa7-f107-493f-b24a-0e0f52de7b05/image%20(2).png"
        : "https://www.hobbyresources.com/piaf/resources/034d8ca1-6a71-4e34-ac22-7f6c0ce29274/image%20(3).png";
    };

    return (
      <div
        className="flex flex-col relative w-full bg-tertiary bg-opacity-75 p-2 rounded-md h-1/4 gap-y-2"
        id="popUpBox"
      >
        <button
          className="absolute rounded-full bg-white mx-auto px-2 top-2 right-2 flex justify-center items-center z-50"
          onClick={() => {
            setContentVisible(!isContentVisible);
          }}
        >
          <span>{isContentVisible ? "-" : "+"} </span>
        </button>
        <CMSButton
          buttonClass={`shadow-lg order-1 lg:order-4 font-black md:w-2/3 mx-auto ${
            isContentVisible ? "text-sm" : "text-xs"
          }`}
          buttonBgClass="bg-primary text-bg-primary shadow-lg"
          buttonText="ENROLL NOW!"
          textClass={`!text-sm md:text-xl ${
            isContentVisible ? "text-xl" : "!text-sm"
          }`}
          onClick={handleClick}
          tracking={{
            expectedResult: "navigate to post-webinar purchase page",
            innerText: "ENROLL NOW!",
            type: "button",
          }}
        />
        <div className="order-3 font-bold text-red-600">
          <CountdownTimer toDate={[end]} short styleType="popup" />
        </div>
        {isContentVisible && (
          <>
            <TextRender
              resource={title}
              className="md:text-lg text-xs bg-white text-center order-2 rounded-full font-bold md:px-8 px-2 flex-center mx-auto"
            />

            <img
              src={selectUrlByBrand(brandAbbreviation)}
              className="md:w-1/2 w-1/4 mx-auto"
            />
          </>
        )}
      </div>
    );
  }
  return null;
};

export default VideoBuyButton;
