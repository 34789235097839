import { WebinarInteraction } from "@combined-curiosity/collector-js";
import CMSButton from "../SubComponents/CMSButton";
import { useState, useEffect } from "react";
import { generateClientSideUUID } from "../../utils/uuid/uuid";
import { BuyButtonInteractivePointOfInterest } from "../cms/types/cmsTypes";
import ensureTrailingSlash from "@/utils/ensureTrailingSlash";

type BuyButtonProps = {
  currentTime: number;
  className?: string;
  trackWebinarEvent: (
    meta: WebinarInteraction,
    uniqueId: string,
    transport?: "beacon" | "xhr" | undefined
  ) => void;
  config: BuyButtonInteractivePointOfInterest;
  webinarInstanceId: string;
  webinarId: string;
  viewType: WebinarInteraction["viewType"];
  isVisible: boolean;
};

const BuyButton = ({
  className,
  currentTime,
  trackWebinarEvent,
  viewType,
  webinarInstanceId,
  config,
  webinarId,
  isVisible,
}: BuyButtonProps) => {
  const [trackedBuyButtonSeen, setTrackedBuyButtonSeen] = useState(false);
  const handleClick = () => {
    const uniqueId = generateClientSideUUID();
    trackWebinarEvent(
      {
        interaction: "clicked_webinar_offer",
        currentVideoOffset: currentTime,
        viewType,
        webinarInstanceId,
        webinarId,
        anonymousWebinarViewer: false,
      },
      uniqueId
    );
    let url = config.metadata.url;
    const utm_medium = "webinar-buy-button";
    const utm_source = "cc-webinar";
    const utm_content = webinarInstanceId;
    const utm_campaign = webinarId;
    if (url.endsWith("/")) {
      url = url.slice(0, -1);
    }
    const params = new URLSearchParams({
      utm_medium,
      utm_source,
      utm_content,
      utm_campaign,
    });
    window.open(`${ensureTrailingSlash(url)}?${params}`, "_blank");
  };

  useEffect(() => {
    if (currentTime > config?.starts_at && isVisible) {
      if (!trackedBuyButtonSeen) {
        const uniqueId = generateClientSideUUID();
        trackWebinarEvent(
          {
            interaction: "content_seen",
            breakpointSeen: "buy_button",
            viewType,
            webinarInstanceId,
            webinarId,
            anonymousWebinarViewer: false,
          },
          uniqueId
        );
        setTrackedBuyButtonSeen(true);
      }
    }
  }, [
    currentTime,
    config.starts_at,
    trackWebinarEvent,
    trackedBuyButtonSeen,
    viewType,
    webinarId,
    webinarInstanceId,
    isVisible,
  ]);

  if (currentTime > config?.starts_at) {
    return (
      <div className={`${className} opacity-50 hover:opacity-100 z-50`}>
        <CMSButton
          buttonClass="shadow-lg"
          buttonBgClass="bg-primary text-bg-primary shadow-lg"
          buttonText="ENROLL NOW!"
          onClick={handleClick}
          tracking={{
            expectedResult: "navigate to post-webinar purchase page",
            innerText: "ENROLL NOW!",
            type: "button",
          }}
        />
        <div className="bg-primary text-white p-2 mt-2 rounded">
          ZERO Risk 30 Day Guarantee
        </div>
      </div>
    );
  }

  return null;
};

export default BuyButton;
